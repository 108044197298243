<template>
  <div class="tw-flex">
    <div
      :data-editable="editable"
      class="tw-relative tw-h-full"
      :class="editable ? 'xl:tw-mx-4' : null"
      @click="edit = true"
      :style="editable ? { height: '106px', width: '106px' } : null"
      :data-loading="loading"
      v-click-outside="() => (edit = false)"
    >
      <object
        :data="src"
        type="image/png"
        :class="`${size === 8 ? 'tw-w-8' : size === 16 ? 'tw-w-16' : 'tw-w-24'} ${
          size === 8 ? 'tw-h-8' : size === 16 ? 'tw-h-16' : 'tw-h-24'
        } tw-object-cover tw-rounded-full`"
        id="avatar"
      >
        <div
          :class="`tw-w-full tw-h-full tw-rounded-full tw-bg-gray-300 tw-flex tw-justify-center tw-items-center ${fontSize} tw-font-bold tw-text-white tw-tracking-wide`"
          v-text="initials"
        />
      </object>
      <div v-if="edit && editable" class="tw-absolute tw-card" style="width: 166px; left: -36px; background-color: white; padding: 6px">
        <shared-btn shape="text" @click.stop="() => $refs.picker.click()" class="tw-text-success hover:bg-gray-100">
          <i class="fas fa-save tw-mr-1" />Carica file
        </shared-btn>
        <shared-btn shape="text" @click.stop="remove" class="text-error hover:tw-bg-gray-100 tw-w-full">
          <i class="fas fa-times-circle" />
          Elimina
        </shared-btn>
      </div>
      <input type="file" class="tw-hidden" ref="picker" @change="upload" />
    </div>
  </div>
</template>

<script lang="ts">
import { useUserStore } from "@/stores/user";
import { useHttp } from "@/composables/useHttp";
import { userRepository } from "@/repositories/users.repository";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "shared-avatar",

  props: {
    name: {
      type: String,
      required: false,
    },
    src: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    size: {
      type: Number,
      required: false,
      default: 8,
      validator(v) {
        if (typeof v !== "number") return false;
        return [8, 16, 24].includes(v);
      },
    },
  },

  setup() {
    const { request, loading } = useHttp();
    const userStore = useUserStore();
    const edit = ref<boolean>(false);
    return {
      edit,
      userStore,
      loading,
      request,
    };
  },

  computed: {
    initials(): string {
      if (!this.name) return "";
      const [firstname, lastname] = this.name.split(" ");
      return firstname[0] + lastname[0];
    },
    fontSize(): string {
      return this.size === 8 ? "text-md" : this.size === 16 ? "text-lg" : "text-4xl";
    },
  },
  methods: {
    remove() {
      this.edit = false;
      this.request(userRepository.removeAvatar, {
        showError: true,
        showSuccess: true,
        successText: "Avatar eliminato, l'aggiornamento sarà propagato in pochi minuti",
      })(this.userStore.user!.uid);
    },

    upload(event: Event) {
      this.edit = false;
      const payload = (event.target as HTMLInputElement).files![0] as Blob;
      this.request(userRepository.updateAvatar, {
        showError: true,
        showSuccess: true,
        successText: "Avatar modificato, l'aggiornamento sarà propagato in pochi minuti",
      })(this.userStore.user!.uid, payload);
    },
  },
});
</script>
<style>
[data-editable="true"][data-loading="false"] {
  border: 5px solid #13696c;
  border-radius: 50%;
}

[data-editable="true"][data-loading="true"] {
  border-color: rgba(0, 0, 0, 0);
}

[data-editable="true"]::before {
  font-family: "Font Awesome\ 5 Free";
  content: "\f304";
  color: white;
  /* FontAwesome Unicode */
  font-weight: 900;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  visibility: hidden;
}

[data-editable="true"][data-loading="false"]:hover::before {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.35);
  transition: all 0.5s;
  cursor: pointer;
}

[data-editable="true"][data-loading="true"]::before {
  pointer-events: none;
  content: "";
  visibility: visible;
  border: 5px solid #13696c;
  border-left-color: rgba(0, 0, 0, 0);
  border-right-color: rgba(0, 0, 0, 0);
  border-top-color: rgba(0, 0, 0, 0);
  animation: rotate linear 1s infinite;
  backdrop-filter: blur(3px);
  width: 110%;
  height: 110%;
  left: -10%;
  top: -10%;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
  }
}
</style>
