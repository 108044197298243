import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31f51f7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-bg-gray-300 tw-rounded-xl tw-my-1 tw-py-1 tw-px-2 tw-text-xs" }
const _hoisted_2 = ["disabled", "data-status", "data-validation"]
const _hoisted_3 = {
  key: 0,
  class: "border-animation"
}
const _hoisted_4 = {
  key: 0,
  class: "tw-card tw-overflow-y-auto text-input",
  style: {"max-height":"300px","padding-left":"0","padding-right":"0"}
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "tw-text-xs tw-text-red-700 tw-mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["tw-flex tw-flex-wrap tw-items-center tw-relative tw-mt-4 tw-mr-4 tw-w-96 form-control tw-border-b-2 tw-border-grey-500 tw-block tw-w-96 tw-py-1.5 tw-items-end text-input", 
      _ctx._errors && _ctx._errors.length > 0
        ? 'focus:tw-text-red-700 focus:tw-border-red-700 focus:tw-outline-none animate__animated animate__headShake'
        : 'focus:tw-text-gray-700 focus:tw-outline-none'
    ])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokens, (token, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "mr-3"
        }, [
          _renderSlot(_ctx.$slots, "chip", {}, () => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(token), 1)
          ], true)
        ]))
      }), 128)),
      _withDirectives(_createElementVNode("input", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showOptions = true)),
        class: "form-control tw-text-base tw-font-normal tw-text-gray-700 tw-bg-transparent tw-bg-clip-padding tw-transition tw-ease-in-out tw-m-0",
        style: {"flex":"1 1","max-width":"100%","min-width":"20px"},
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.scopedModelValue) = $event)),
        type: "text",
        disabled: _ctx.disabled,
        "data-status": _ctx.scopedModelValue || _ctx.tokens.length > 0 ? 'filled' : 'empty',
        "data-validation": _ctx._errors && _ctx._errors.length > 0 ? 'invalid' : 'valid'
      }, null, 8, _hoisted_2), [
        [_vModelText, _ctx.scopedModelValue],
        [_directive_click_outside, 
        () => {
          _ctx.split();
          _ctx.showOptions = false;
        }
      ]
      ]),
      _createElementVNode("label", {
        class: _normalizeClass(["floating-label", _ctx._errors && _ctx._errors.length > 0 ? 'tw-text-red-700' : 'tw-text-gray-700'])
      }, _toDisplayString(_ctx.required ? `${_ctx.label} *` : _ctx.label), 3),
      (!_ctx._errors || _ctx._errors.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx.showOptions && _ctx.filteredOptions.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "tw-p-4 tw-cursor-pointer hover:tw-bg-gray-100",
                  key: i,
                  onClick: () => _ctx.push(option)
                }, _toDisplayString(option), 9, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx._errors && _ctx._errors.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx._errors[0]), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}