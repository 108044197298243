<template>
  <div v-if="show" class="tw-pb-4 tw-w-60">
    <slot></slot>
    <div class="d-flex tw-gap-1 tw-items-center">
      <slot name="label">
        <p class="tw-text-gray-600 tw-text-xs tw-truncate">{{ label }}</p>
      </slot>
      <slot name="label-append" />
    </div>
    <slot name="text">
      <p class="tw-text-gray-700 tw-truncate">{{ text }}</p>
    </slot>
  </div>
</template>
<script lang="ts" setup>
import { computed, useSlots } from "vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
});
const slots = useSlots();
const show = computed(() => Boolean(props.text || slots["text"]) && (props.label || slots["label"]));
</script>
