import { addMethod, number, string } from "yup";

addMethod(string, "phone", function () {
  // eslint-disable-next-line no-useless-escape
  const mobileNumberRegexp = /^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/;
  return this.test("phone-number", "Numero invalido", function (value: string) {
    return !value || mobileNumberRegexp.test(value!);
  });
});

addMethod(number, "between", function (min: number, max: number) {
  return this.test({
    name: "between",
    params: { min, max },
    message: "Il campo ${path} deve essere compreso tra ${min} e ${max}",
    test: (value) => {
      const numberAsValue = Number(value);
      return !isNaN(numberAsValue) && numberAsValue >= min && numberAsValue <= max;
    },
  });
});
