import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { privateRoutes } from "@/router/routes/private";
import { publicRoutes } from "@/router/routes/public";

const routes: Array<RouteRecordRaw> = [
  ...privateRoutes,
  ...publicRoutes,
  {
    path: "/403",
    name: "403",
    component: () => import(/* webpackChunkName: "403-view" */ "@/views/errors/403.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import(/* webpackChunkName: "404-view" */ "@/views/errors/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
