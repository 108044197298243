import { RouteRecordRaw } from "vue-router";
import { isLoading, isAuthenticated, redirectNotificationsCenter } from "@/router/guards";
import myOrgCompany from "@/router/routes/private/my-org-company";
import mySplCompany from "@/router/routes/private/my-spl-company";
import myUsrCompany from "@/router/routes/private/my-usr-company";
import account from "@/router/routes/private/account";

export const privateRoutes: Array<RouteRecordRaw> = [
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "login-view" */ "@/views/private/Logout.vue"),
    beforeEnter: [isLoading, isAuthenticated],
  },

  {
    path: "/redirect",
    name: "Redirect",
    component: () => import(/* webpackChunkName: "redirect-view" */ "@/views/private/Redirect.vue"),
    beforeEnter: [isLoading, isAuthenticated],
  },

  {
    path: "/private",
    component: () => import(/* webpackChunkName: "redirect-view" */ "@/views/Private.vue"),
    beforeEnter: [isLoading, isAuthenticated],
    children: [
      {
        path: "launcher",
        name: "Launcher",
        component: () => import(/* webpackChunkName: "launcher-view" */ "@/views/private/Launcher.vue"),
      },
      {
        path: "notifications-center",
        name: "NotificationsCenter",
        component: () => import(/* webpackChunkName: "launcher-view" */ "@/views/private/NotificationsCenter.vue"),
      },
      myOrgCompany,
      mySplCompany,
      myUsrCompany,
      account,
    ],
  },
].map((route) => ({ ...route, beforeEnter: [...route.beforeEnter, redirectNotificationsCenter] }));
