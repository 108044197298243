import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", null, [
    _createElementVNode("i", {
      class: "fas fa-arrow-left tw-text-gray-500",
      style: {"font-size":"24px"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.$router.push({
          name: 'Launcher',
        })
      ))
    })
  ]))
}