<script>
import { defineComponent, ref } from "vue";
import { throttled, isTextTruncatedEl } from "@/lib/functions";

export default defineComponent({
  name: "TextTruncated",

  props: {
    as: {
      type: String,
      default: () => "p",
    },

    text: {
      required: true,
      type: String,
    },

    tooltipProps: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const textTruncatedEl = ref(null);
    const observer = ref(null);
    const overflowing = ref(false);
    const width = ref(0);

    const onResize = throttled((entries) => {
      width.value = entries[0].contentBoxSize[0].inlineSize;
      if (isTextTruncatedEl(textTruncatedEl.value, width.value)) {
        overflowing.value = true;
      } else {
        overflowing.value = false;
      }
    }, 100);

    return { textTruncatedEl, overflowing, observer, width, onResize };
  },

  mounted() {
    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.textTruncatedEl);
  },

  updated() {
    if (this.observer) this.observer.unobserve(this.textTruncatedEl);
    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.textTruncatedEl);
  },

  beforeUnmount() {
    if (this.observer) this.observer.unobserve(this.textTruncatedEl);
  },

  computed: {
    defaultTooltipProps() {
      return {
        location: "top",
        openDelay: 250,
        maxWidth: this.width * 3,
        text: this.text,
      };
    },
  },
});
</script>

<template>
  <component :is="as" ref="textTruncatedEl" :class="{ 'tw-truncate': overflowing }">
    {{ text }}
    <v-tooltip v-if="overflowing" v-bind="{ ...defaultTooltipProps, ...tooltipProps }" activator="parent" />
  </component>
</template>
