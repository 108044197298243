import { onBeforeUnmount, onMounted } from "vue";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onKeyUp(config: Record<string, (...args: any[]) => void>) {
  return (evt: KeyboardEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (config[evt.key]) {
      config[evt.key](evt);
    }
  };
}

export function useKeyUp() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const detect = (config: Record<string, (...args: any[]) => void>) => {
    const detector = onKeyUp(config);
    onMounted(() => {
      window?.addEventListener("keyup", detector);
    });
    onBeforeUnmount(() => {
      window?.removeEventListener("keyup", detector);
    });
  };
  return {
    detect,
  };
}
