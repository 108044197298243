import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tr", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (k, label) => {
        return (_openBlock(), _createElementBlock("th", { key: k }, [
          _renderSlot(_ctx.$slots, `${_ctx.value}-header`, { label: label }, () => [
            _createTextVNode(_toDisplayString(label), 1)
          ])
        ]))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("tr", { key: i }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value, j) => {
          return (_openBlock(), _createElementBlock("td", { key: j }, [
            _renderSlot(_ctx.$slots, `${value}-col`, {
              item: item,
              value: value
            }, () => [
              _createTextVNode(_toDisplayString(item[value]), 1)
            ])
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}