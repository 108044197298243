import { useUserStore } from "@/stores/user";
import { RouteLocationNormalized as Route, NavigationGuardNext as Next, LocationQueryValue, RouteLocation } from "vue-router";
import { dialogService } from "@/services/dialog.service";
import { useFCM } from "@/composables/useFCM";

type HomeQuery = { to?: string | LocationQueryValue[]; profile_uid?: string | LocationQueryValue[] };
function extractHomeQueryParams(to: Route): HomeQuery {
  const { to: to_param, profile_uid: profile_uid_param } = to.query;
  const query = {} as HomeQuery;
  if (to_param) query["to"] = to_param;
  if (profile_uid_param) query["profile_uid"] = profile_uid_param;
  return query;
}

/**
 * Sospende la navigazione (reindirizzamento in Check) se l'autenticazione è in corso,
 * prosegue altrimenti
 * @param to: rotta di destinazione
 * @param _from: rotta di partenza
 * @param next: next handler
 */
export function isLoading(to: Route, _from: Route, next: Next): void {
  const userStore = useUserStore();

  const isStillLoading = userStore.pending;
  if (isStillLoading) {
    const { query, params, name: back } = to;
    next({
      name: "Check",
      query: {
        ...query,
        back: back === "Home" ? "Launcher" : (back as string),
        params: encodeURIComponent(JSON.stringify(params)),
      },
    });
  } else {
    next();
  }
}

/**
 * Blocca la navigazione (reindirizzamento in Home) se l'utente non è autenticato,
 * prosegue altrimenti
 * @param _to: rotta di destinazione
 * @param _from: rotta di partenza
 * @param next: next handler
 */
export function isAuthenticated(to: Route, from: Route, next: Next): void {
  const userStore = useUserStore();

  const userIsAuthenticated = userStore.user !== null && userStore.user !== undefined;

  if (userIsAuthenticated) {
    next();
  } else {
    if (from.name === "Check") {
      next({
        name: "Login",
        query: extractHomeQueryParams(to),
      });
    } else {
      next(false);
    }
  }
}

export function isNotAuthenticated(to: Route, from: Route, next: Next): void {
  const userStore = useUserStore();

  const userIsAuthenticated = userStore.user !== null && userStore.user !== undefined;

  if (!userIsAuthenticated) {
    next();
  } else {
    if (from.name === "Check") {
      next({
        name: "Home",
        query: extractHomeQueryParams(to),
      });
    } else {
      next(false);
    }
  }
}

function preventIfAuthenticated(onPrevent: (to: Route, from: Route) => Promise<RouteLocation>) {
  return async function (to: Route, from: Route) {
    const userStore = useUserStore();
    const userIsAuthenticated = userStore.user !== null && userStore.user !== undefined;

    if (!userIsAuthenticated) {
      return true;
    } else {
      return onPrevent(to, from);
    }
  };
}

const logout = () => {
  const userStore = useUserStore();
  const { stopListeningForNotifications } = useFCM();

  return stopListeningForNotifications()
    .catch(() => {
      /** do nothing */
    })
    .then(() => userStore.signOut(true));
};

export const preventSignUpMagicLinkIfAuthenticated = preventIfAuthenticated(async function onPrevent(to: Route) {
  try {
    await dialogService.showDialog({
      title: "Attenzione",
      description: "Non è possibile usare questo link se hai già effettuato l'accesso",
      successButtonText: "Logout",
      errorButtonText: "Chiudi",
    });
    await logout();
    return { name: "Login" } as RouteLocation;
  } catch {
    return {
      name: "Home",
      query: extractHomeQueryParams(to),
    } as RouteLocation;
  } finally {
    dialogService.closeDialog();
  }
});

export const preventLoginMagicLinkIfAuthenticated = preventIfAuthenticated(async function onPrevent(to: Route) {
  try {
    await dialogService.showDialog({
      title: "Attenzione",
      description: "Non è possibile accedere attraverso un link se se hai già effettuato l'accesso",
      successButtonText: "Logout",
      errorButtonText: "Chiudi",
    });
    await logout();
    return { name: "Login" } as RouteLocation;
  } catch {
    return {
      name: "Home",
      query: extractHomeQueryParams(to),
    } as RouteLocation;
  } finally {
    dialogService.closeDialog();
  }
});

export const redirectNotificationsCenter = (to: Route, from: Route, next: Next): void => {
  const { query } = from;
  if (Number.parseInt(query["noti"] as string) === 1) {
    delete query["noti"];
    next({ name: "NotificationsCenter", query });
  } else next();
};
