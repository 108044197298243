import { authenticationService } from "@/services/authentication.service";
import { httpService } from "@/services/http.service";
import { userEndpointsVO } from "@/vos/user/endpoints.vo";
import { UserAnagraficaDTO, UserAnagraficaUpdateDTO } from "@/dto/user/UserAnagrafica.dto";
import { pipeAsync } from "@/lib/functions";
import { httpHeadersVO } from "@/vos/http/headers.vo";
import { makeBinded } from "@/proxies";

export class UserRepository {
  constructor(
    private readonly _http = httpService,
    private readonly _headers = httpHeadersVO,
    private readonly _authService = authenticationService,
    private readonly _endpoints = userEndpointsVO
  ) {}

  /**
   *
   * @param id
   * @returns
   */
  async getAnagrafica(id: string) {
    return this._http.aGet<UserAnagraficaDTO>(this._endpoints.findOwn({ id }), {
      headers: await pipeAsync(this._headers.json, this._headers.autenticated)({}),
    });
  }

  /**
   * Aggiorna l'anagrafica di un utente
   * @param id
   * @param anagrafica
   * @returns
   */
  async updateAnagrafica(id: string, anagrafica: UserAnagraficaUpdateDTO) {
    // 1. Update anagrafica
    const res = await this._http.aPatch<string>(this._endpoints.updateOwn({ id }), {
      headers: await pipeAsync(this._headers.autenticated, this._headers.json)({}),
      body: JSON.stringify({ data: anagrafica }),
    });
    // 2. Sync firebase data
    await authenticationService.refresh(true);
    return res;
  }

  /**
   *
   * @param email
   * @returns
   */
  resetPassword(email: string) {
    return this._authService.resetPassword(email);
  }

  /**
   *
   * @param oldPassword
   * @param newPassword
   * @returns
   */
  changePassword(oldPassword: string, newPassword: string) {
    return this._authService.changePassword(oldPassword, newPassword);
  }

  /**
   *
   * @returns
   */
  getProfiles() {
    return this._authService.getProfiles();
  }

  /**
   *
   * @param id
   * @returns
   */
  async removeAvatar(id: string) {
    return this._http.aDelete<string>(this._endpoints.deleteAvatar({ id }), {
      headers: await pipeAsync(this._headers.autenticated)({}),
    });
  }

  /**
   *
   * @param id
   * @param avatar
   * @returns
   */
  async updateAvatar(id: string, avatar: Blob) {
    const fd = new FormData();
    fd.append("file", avatar);

    await this.removeAvatar(id);

    return this._http.aPost<string>(this._endpoints.updateAvatar({ id }), {
      headers: await pipeAsync(this._headers.autenticated)({}),
      body: fd,
    });
  }
}

export const userRepository = makeBinded(UserRepository);
