<template>
  <div
    class="tw-flex tw-flex-wrap tw-items-center tw-relative tw-mt-4 tw-mr-4 tw-w-96 form-control tw-border-b-2 tw-border-grey-500 tw-block tw-w-96 tw-py-1.5 tw-items-end text-input"
    :class="
      _errors && _errors.length > 0
        ? 'focus:tw-text-red-700 focus:tw-border-red-700 focus:tw-outline-none animate__animated animate__headShake'
        : 'focus:tw-text-gray-700 focus:tw-outline-none'
    "
  >
    <div v-for="(token, i) of tokens" :key="i" class="mr-3">
      <slot name="chip">
        <div class="tw-bg-gray-300 tw-rounded-xl tw-my-1 tw-py-1 tw-px-2 tw-text-xs">{{ token }}</div>
      </slot>
    </div>

    <input
      @click="showOptions = true"
      class="form-control tw-text-base tw-font-normal tw-text-gray-700 tw-bg-transparent tw-bg-clip-padding tw-transition tw-ease-in-out tw-m-0"
      style="flex: 1 1; max-width: 100%; min-width: 20px"
      v-model="scopedModelValue"
      type="text"
      :disabled="disabled"
      :data-status="scopedModelValue || tokens.length > 0 ? 'filled' : 'empty'"
      :data-validation="_errors && _errors.length > 0 ? 'invalid' : 'valid'"
      v-click-outside="
        () => {
          split();
          showOptions = false;
        }
      "
    />

    <label class="floating-label" :class="_errors && _errors.length > 0 ? 'tw-text-red-700' : 'tw-text-gray-700'"> {{ required ? `${label} *` : label }}</label>
    <div class="border-animation" v-if="!_errors || _errors.length === 0" />
  </div>
  <transition enter-active-class="animate__animated animate__fadeIn" appear>
    <div
      class="tw-card tw-overflow-y-auto text-input"
      style="max-height: 300px; padding-left: 0; padding-right: 0"
      v-if="showOptions && filteredOptions.length > 0"
    >
      <div class="tw-p-4 tw-cursor-pointer hover:tw-bg-gray-100" v-for="(option, i) of filteredOptions" :key="i" @click="() => push(option)">{{ option }}</div>
    </div>
  </transition>

  <transition enter-active-class="animate__animated animate__fadeIn" appear>
    <p class="tw-text-xs tw-text-red-700 tw-mt-2" v-if="_errors && _errors.length > 0">{{ _errors[0] }}</p>
  </transition>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useKeyUp } from "@/composables/useKeyUp";
export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    modelValue: {
      type: String,
      required: false,
    },

    errors: {
      type: Array as PropType<Array<string>>,
      required: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    options: {
      type: Array as PropType<string[]>,
      required: false,
      default: null,
    },

    rules: {
      type: Array as PropType<((token: string) => Maybe<string>)[]>,
      required: false,
      default: [] as ((token: string) => Maybe<string>)[],
    },
  },

  setup() {
    const { detect } = useKeyUp();
    return {
      detect,
    };
  },

  created() {
    this.detect({
      Enter: this.split.bind(this),
      Backspace: this.pop.bind(this),
    });
  },

  emits: ["update:modelValue"],

  computed: {
    filteredOptions(): string[] {
      return this.options.filter((opt) => opt.indexOf(this.scopedModelValue || "") >= 0 && !this.tokens.includes(opt));
    },
    _errors(): string[] {
      return (this.errors ?? []).concat(this.validationErrors);
    },
  },

  data: () => ({
    scopedModelValue: "" as string | null,
    show: false,
    tokens: [] as string[],
    showOptions: false,
    prevent: false,
    validationErrors: [] as string[],
  }),

  methods: {
    push(v: string) {
      this.scopedModelValue = "";
      this.tokens.push(v);
    },

    pop() {
      if (!this.scopedModelValue && !this.prevent) {
        this.tokens.pop();
      } else {
        this.prevent = false;
      }
    },
    split() {
      const v = this.scopedModelValue;
      if (v) {
        this.validationErrors = [];
        this.rules.forEach((validate) => {
          const errorMessage = validate(v);
          if (errorMessage) this.validationErrors.push(errorMessage);
        });

        if (this.validationErrors.length === 0) {
          this.scopedModelValue = "";
          this.tokens.push(v);
        }
      }
    },
  },

  watch: {
    scopedModelValue(v) {
      this.prevent = v === "";
    },
    modelValue: {
      immediate: true,
      handler(v) {
        if (v) this.tokens = v.split(",").filter((e: string) => e);
      },
    },
    tokens: {
      immediate: true,
      deep: true,
      handler(v) {
        this.$emit("update:modelValue", v.join(",") || null);
      },
    },
  },
});
</script>
<style scoped>
.text-input {
  min-width: 300px;
  width: 100%;
  max-width: min(80vw, 600px);
}

input:focus-visible {
  border: none;
  outline: none;
}

input + .floating-label + .border-animation {
  position: absolute;
  bottom: -1px;
  left: 50%;
  height: 2px;
  width: 0;
}

.floating-label {
  position: absolute;
  top: 6px;
  transition: all 200ms;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}

.floating-label:hover {
  cursor: text;
}

/**
   * FOCUSED INPUT CSS
   */

input[data-status="filled"] + .floating-label,
input:focus + .floating-label {
  visibility: visible;
  position: absolute;
  top: -12px;
  font-size: 12px;
  transition: all 200ms 1ms;
  color: rgba(0, 0, 0, 0.45);
}

input:focus + .floating-label {
  color: rgba(37, 99, 235, 1);
  transition: all 200ms;
}

input:focus + .floating-label + .border-animation {
  width: 100%;
  left: 0;
  background-color: rgba(37, 99, 235, 1);
  transition: all 200ms;
}

/**
  * INVALID INPUT CSS
  */
input[data-validation="invalid"],
input[data-validation="invalid"] + .floating-label {
  color: rgba(185, 28, 28, 1) !important;
  transition: all 200ms;
}

input:disabled {
  color: rgba(0, 0, 0, 0.38);
  border-style: dotted;
}
</style>
