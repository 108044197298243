import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_service_component = _resolveComponent("dialog-service-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NotificationsList = _resolveComponent("NotificationsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dialog_service_component),
    (_openBlock(), _createBlock(_component_router_view, { key: _ctx.key }, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
      ]),
      _: 1
    })),
    _createVNode(_component_NotificationsList)
  ]))
}