/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NavigationGuardNext as Next, RouteLocationNormalized as Route } from "vue-router";
import { StorageFactory } from "@/factories/storage.factory";

// ! This creates a circular dependency
import { getRequiredParams } from "@/router/utils/getRequiredParams";

const storage = StorageFactory("localStorage");
const routeKey = "route";

/**
 * TODO: documentazione
 */
export function store(namespace: string) {
  return async (to: Route, _from: Route, next: Next): Promise<void> => {
    const { query } = to;

    let { back: name } = query;

    const params = query.params ? JSON.parse(decodeURIComponent(query.params as string)) : {};
    delete to.query.params;

    const requiredParams = await getRequiredParams({ routeProperty: "name", value: String(name) });
    if (requiredParams.some((param) => params[param] === undefined || params[param] === null)) {
      name = "Launcher";
    }

    delete query.back;

    storage.setItem(`${namespace}/${routeKey}`, {
      name,
      query,
      params,
    });

    next();
  };
}

/**
 * TODO: documentazione
 */
export function restore(namespace: string) {
  return (): Route | string => {
    const route = storage.getItem<Route>(`${namespace}/${routeKey}`) || "/";
    storage.removeItem(`${namespace}/${routeKey}`);
    return route;
  };
}
