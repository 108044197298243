export default {
  path: "account",
  name: "Account",
  component: () => import(/* webpackChunkName: "my-account-view" */ "@/views/private/MyUser.vue"),
  children: [
    {
      path: "preferenze-notifiche",
      name: "NotificationPreferences",
      component: () => import(/* webpackChunkName: "my-account-preferences" */ "@/components/my-user/NotificationsPreferences.vue"),
    },
    {
      path: "anagrafica",
      name: "Anagrafica",
      component: () => import(/* webpackChunkName: "my-account-anagrafica" */ "@/components/my-user/Anagrafica.vue"),
    },
    {
      path: "password",
      name: "UpdatePassword",
      component: () => import(/* webpackChunkName: "my-account-password" */ "@/components/my-user/UpdatePassword.vue"),
    },
  ],
};
