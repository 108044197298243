<template>
  <button @click="refresh">
    <i class="fas fa-redo tw-text-gray-500" style="font-size: 18px" />
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useRefresh } from "@/composables/useRefresh";
export default defineComponent({
  setup() {
    const { refresh } = useRefresh();
    return {
      refresh,
    };
  },
});
</script>
