import { RouteRecordRaw } from "vue-router";
import { isLoading, preventLoginMagicLinkIfAuthenticated, preventSignUpMagicLinkIfAuthenticated } from "@/router/guards";
import { augWindow } from "@/plugins/context";
import { isNotAuthenticated } from "@/router/guards";
import { store, restore } from "@/router/middlewares";

export const publicRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    redirect: {
      name: "Launcher",
    },
  },

  {
    path: "/check",
    name: "Check",
    component: () => import(/* webpackChunkName: "login-view" */ "@/views/public/Check.vue"),
    beforeEnter: store(augWindow.ctx),
  },

  {
    path: "/restore",
    name: "Restore",
    redirect: restore(augWindow.ctx),
  },

  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login-view" */ "@/views/public/Login.vue"),
    beforeEnter: [isLoading, isNotAuthenticated],
  },

  {
    path: "/magic-link/login",
    name: "MagicLinkLogin",
    component: () => import(/* webpackChunkName: "login-view" */ "@/views/public/MagicLinkLogin.vue"),
    beforeEnter: [isLoading, preventLoginMagicLinkIfAuthenticated],
  },

  {
    path: "/signup/:id",
    name: "SignUp",
    component: () => import(/* webpackChunkName: "login-view" */ "@/views/public/SignUp.vue"),
    beforeEnter: [isLoading],
  },

  {
    path: "/magic-link/registration",
    name: "MagicLinkSignup",
    component: () => import(/* webpackChunkName: "login-view" */ "@/views/public/MagicLinkSignUp.vue"),
    beforeEnter: [isLoading, preventSignUpMagicLinkIfAuthenticated],
  },

  /**
   * Fix temporaneo:
   * Le vecchie email per notificare SPL dei vari token portavano ad un URL inutilizzato
   * `/landing`. Per retrocompatibilita' viene fatto un redirect alla pagina corretta.
   *
   * @note Le email sono state fixate ma essendo periodiche (una volta al mese) bisogna
   * tenere questo fix fino almeno il 01/01/2024
   */
  {
    path: "/landing",
    redirect: {
      name: "NotificationsCenter",
      query: {
        email: 1,
      },
    },
  },
];
