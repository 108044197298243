import { setLocale } from "yup";

setLocale({
  mixed: {
    default: "Campo non valido",
    required(params) {
      return `Il campo ${params.label} è obbligatorio`;
    },
  },
  string: {
    min(params) {
      return `Il campo ${params.label} deve contenere almeno ${params.min} caratteri`;
    },
    max(params) {
      return `Il campo ${params.label} deve contenere al massimo ${params.max} caratteri`;
    },
    length(params) {
      return `Il campo ${params.label} deve contenere esattamente ${params.length} caratteri`;
    },
    email(params) {
      return `Il campo ${params.label} deve essere una email valida`;
    },
    matches(params) {
      return `Il campo ${params.label} non è valido`;
    },
    // @ts-expect-error ts(7006)
    phone(params) {
      return `Il campo ${params.label} non è un numero di telefono valido`;
    },
  },
  number: {
    positive(params) {
      return `Il campo ${params.label} deve essere un numero positivo`;
    },
    integer(params) {
      return `Il campo ${params.label} deve essere un numero intero`;
    },
  },
});
