import { uuid } from "@/lib/random";
import { ref } from "vue";
import { authenticationService } from "@/services/authentication.service";

const key = ref<string>(uuid());

async function refresh() {
  key.value = uuid();
  authenticationService.refresh(true);
}

export function useRefresh() {
  return {
    key,
    refresh,
  };
}
