<template>
  <Teleport v-if="isMounted" :to="to">
    <slot />
  </Teleport>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isMounted: false,
  }),

  mounted() {
    this.isMounted = true;
  },
});
</script>
