<template>
  <div>
    <div class="tw-relative tw-mt-4 lg:tw-pr-4 text-input">
      <input
        v-model="model"
        :autocomplete="autocomplete"
        :type="!hideable ? 'text' : show ? 'text' : 'password'"
        :disabled="disabled"
        :data-status="model ? 'filled' : 'empty'"
        :data-validation="errors && errors.length > 0 ? 'invalid' : 'valid'"
        style="border-bottom: 2px solid lightgrey"
        :class="
          errors && errors.length > 0
            ? 'focus:tw-text-red-700 focus:tw-border-red-700 focus:tw-outline-none animate__animated animate__headShake'
            : 'focus:tw-text-gray-700 focus:tw-outline-none'
        "
        class="form-control tw-border-b-2 tw-border-gray-500 tw-block tw-w-full tw-py-1.5 tw-text-base tw-font-normal tw-text-gray-700 tw-bg-transparent tw-bg-clip-padding tw-transition tw-ease-in-out tw-m-0"
      />

      <label class="floating-label" :class="errors && errors.length > 0 ? 'tw-text-red-700' : 'tw-text-gray-700'"> {{ required ? `${label} *` : label }}</label>
      <div @click="show = !show" class="tw-absolute tw-right-2 tw-block tw-z-10 tw-top-2 tw-cursor-pointer" v-if="hideable">
        <i :class="!show ? 'fas fa-eye' : 'fas fa-eye-slash'" />
      </div>
      <div class="border-animation lg:tw-pr-4" v-if="!errors || errors.length === 0" />
    </div>
    <transition enter-active-class="animate__animated animate__fadeIn" appear>
      <p class="tw-text-xs tw-text-red-700 tw-mt-2" v-if="errors && errors.length > 0">{{ errors[0] }}</p>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import { PropType, ref } from "vue";

const { autocomplete, disabled, label, errors, required, hideable } = defineProps({
  autocomplete: {
    type: String,
    required: false,
    default: null,
  },

  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },

  label: {
    type: String,
    required: true,
  },

  errors: {
    type: Array as PropType<Array<string>>,
    required: false,
  },

  required: {
    type: Boolean,
    required: false,
    default: false,
  },

  hideable: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const [model, modifiers] = defineModel({
  set(value: string) {
    if (modifiers.nullify) return value || null;
    return value;
  },
});

const show = ref(false);
</script>
<style scoped>
.text-input {
  min-width: 300px;
  width: 100%;
  max-width: min(80vw, 600px);
}

input {
  background-color: transparent;
}

input + .floating-label + .border-animation {
  position: absolute;
  bottom: 0px;
  left: 50%;
  height: 2px;
  width: 0;
}

.floating-label {
  position: absolute;
  top: 6px;
  transition: all 200ms;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}

.floating-label:hover {
  cursor: text;
}

/**
 * FOCUSED INPUT CSS
 */

input[data-status="filled"] + .floating-label,
input:focus + .floating-label {
  visibility: visible;
  position: absolute;
  top: -9px;
  font-size: 12px;
  transition: all 200ms 1ms;
  color: rgba(0, 0, 0, 0.45);
}

input:focus + .floating-label {
  color: rgba(37, 99, 235, 1);
  transition: all 200ms;
}

input:focus + .floating-label + .border-animation {
  width: 100%;
  left: 0;
  background-color: rgba(37, 99, 235, 1);
  transition: all 200ms;
}

/**
* INVALID INPUT CSS
*/
input[data-validation="invalid"],
input[data-validation="invalid"] + .floating-label {
  color: rgba(185, 28, 28, 1) !important;
  transition: all 200ms;
}

input:disabled {
  color: rgba(0, 0, 0, 0.38);
  border-style: dotted;
}
</style>
