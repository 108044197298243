import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tw-modal tw-card tw-w-full tw-bg-white tw-h-auto",
  style: {"max-width":"500px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)), ["stop"])),
      class: "tw-inline-block"
    }, [
      _renderSlot(_ctx.$slots, "open")
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.opened)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "overlay tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-start tw-p-6",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"])),
            id: "shared-modal"
          }, [
            _createVNode(_Transition, { class: "animate__animated animate__zoomIn animate__faster" }, {
              default: _withCtx(() => [
                (_ctx.showModal)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _renderSlot(_ctx.$slots, "close", { key: _ctx.key }),
                      _renderSlot(_ctx.$slots, "content", {
                        close: _ctx.close,
                        lock: _ctx.lock,
                        unlock: _ctx.unlock,
                        key: _ctx.key
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            })
          ]))
        : _createCommentVNode("", true)
    ]))
  ]))
}