import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-190c48aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "hoverBound",
  onmouseover: "this.children[1].setAttribute('hover', 'true')",
  onmouseout: "this.children[1].setAttribute('hover', 'false')"
}
const _hoisted_2 = { class: "sharedTooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "activator", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}