import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialog) = $event)),
    width: "600px",
    persistent: _ctx.persistent
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "pa-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "text-grey-darken-1 px-4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.description), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, {
            class: _normalizeClass(['d-flex', _ctx.showErrorButton ? 'justify-space-between' : 'justify-end', 'mt-4'])
          }, {
            default: _withCtx(() => [
              (_ctx.showErrorButton)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.errorButtonClick,
                    color: "grey-darken-1"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.errorButtonText), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_btn, {
                onClick: _ctx.successButtonClick,
                color: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.successButtonText), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "persistent"]))
}