<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      dialog: false,
      title: "",
      description: "",
      successButtonText: "",
      errorButtonText: "",
      showErrorButton: true,
      persistent: true,
    };
  },

  mounted() {
    window.addEventListener("show-dialog", this.showDialog);
    window.addEventListener("close-dialog", this.closeDialog);
  },

  unmounted() {
    window.removeEventListener("show-dialog", this.showDialog);
    window.removeEventListener("close-dialog", this.closeDialog);
  },

  methods: {
    showDialog(e: Event) {
      const { title, description, successButtonText, errorButtonText, showErrorButton, persistent } = (e as CustomEvent).detail;

      this.title = title;
      this.description = description;
      this.successButtonText = successButtonText;
      this.errorButtonText = errorButtonText;
      this.showErrorButton = showErrorButton;
      this.persistent = persistent;

      this.$nextTick(() => {
        this.dialog = true;
      });
    },

    closeDialog() {
      this.dialog = false;
    },

    successButtonClick() {
      window.dispatchEvent(new CustomEvent("success-button-click", { detail: { successButtonClicked: true } }));
      this.dialog = false;
    },

    errorButtonClick() {
      window.dispatchEvent(new CustomEvent("error-button-click", { detail: { errorButtonClicked: true } }));
      this.dialog = false;
    },
  },
});
</script>

<template>
  <v-dialog v-model="dialog" width="600px" :persistent="persistent">
    <v-card class="pa-4">
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text class="text-grey-darken-1 px-4"> {{ description }} </v-card-text>
      <v-card-actions :class="['d-flex', showErrorButton ? 'justify-space-between' : 'justify-end', 'mt-4']">
        <v-btn v-if="showErrorButton" @click="errorButtonClick" color="grey-darken-1">{{ errorButtonText }}</v-btn>
        <v-btn @click="successButtonClick" color="success">{{ successButtonText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
