<template>
  <div id="main">
    <dialog-service-component />
    <!-- ROUTER NAVIGATION -->
    <router-view v-slot="{ Component }" :key="key">
      <component :is="Component" />
    </router-view>

    <!-- NOTIFICATIONS -->
    <NotificationsList />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useUserStore } from "@/stores/user";
import { useRefresh } from "@/composables/useRefresh";

import NotificationsList from "@/components/notifications/notificationsFCM/NotificationsList.vue";
import DialogServiceComponent from "@/components/_shared/DialogServiceComponent.vue";
import NotificationsAskPermission from "@/components/notifications/notificationsFCM/NotificationsAskPermission.vue";

export default defineComponent({
  components: {
    NotificationsList,
    NotificationsAskPermission,
    DialogServiceComponent,
  },

  setup() {
    const userStore = useUserStore();
    const { key } = useRefresh();

    return {
      userStore,
      key,
    };
  },

  watch: {
    "useUserStore.user"(v, oldv) {
      const hasLoggedOut = !v && oldv;
      const isNotHome = this.$route.name !== "Login";
      if (hasLoggedOut && isNotHome) {
        // L'utente si è sloggato ma si trova in una pagina riservata
        this.$router.push({
          name: "Login",
        });
      }
    },
  },
});
</script>
