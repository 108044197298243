/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const identity = <T>(v: T): T => v;
export const unique = <T>(entityId: T, index: number, array: T[]) => array.indexOf(entityId) === index;
export const isThruthly = <T>(v: T): boolean => Boolean(v);
export const isFalsy = <T>(v: T): boolean => !isThruthly(v);

export const negate = (v: unknown): boolean => !identity(v);
export const opposite = (v: number): number => v * -1;

/**
 * TODO: documentazione
 * @param fns
 * @returns
 */
export const reduceAND =
  <T extends (...args: any) => boolean>(...fns: T[]) =>
  (...args: any[]) =>
    fns.reduce((acc, fn) => acc && fn(...args), true);

/**
 * TODO: documentazione
 * @param fns
 * @returns
 */
export const reduceOR =
  <T extends (...args: any) => boolean>(...fns: T[]) =>
  (...args: any[]) =>
    fns.reduce((acc, fn) => acc || fn(...args), false);

/**
 * TODO: documentazione
 * @param fns
 * @returns
 */
export const pipe =
  /*
   * TODO: pipe potrebbe accettare funzioni in cui il tipo dei parametri e il tipo di ritorno sono per coppia
   * es: object => number; number => string; string => string ...
   */


    <T extends (...args: any[]) => any>(...fns: T[]) =>
    (...args: any) =>
      fns.reduce((v, fn) => fn(v), args);
/**
 *
 * @param fns
 * @returns
 */
export const pipeAsync =
  <T extends (arg: any) => any>(...fns: T[]) =>
  async (arg: any): Promise<any> => {
    let res = arg;
    for (const fn of fns) {
      res = await fn(res);
    }
    return res;
  };

/**
 * Esegue una funziona una sola volta
 * @param fn: la funzione da eseguire
 * @returns il risultato della funzione alla prima invocazione, undefined altrimenti
 */
export const once = <T extends (...args: any[]) => any>(fn: T) => {
  let called = false;
  return (...args: Parameters<T>): Maybe<ReturnType<T>> => {
    if (!called) {
      called = true;
      return fn(...args);
    } else {
      return undefined;
    }
  };
};

export const withSearchParams = (url: string, options?: Record<string, any>) => {
  if (!options) return url;
  return `${url}?${new URLSearchParams(Object.entries(options).filter(([key, value]) => value))}`;
};

export const replaceRouteToWithoutQuery = () => {
  const url = new URL(window.location.toString());
  url.search = "";
  window.location.href = url.toString();
};

export const groupBy = <T extends Record<string, any>>(list: T[], property: keyof T) => {
  const grouped: Record<string, T[]> = {};
  list.forEach((item) => {
    grouped[item[property]] ??= [];
    grouped[item[property]]?.push(item);
  });
  return grouped;
};

export const transformEntries = <I extends keyof T & string, T extends Record<I, unknown>, ResultIndex extends string | symbol | number, ResultValue>(
  obj: T,
  transformer: ([key, value]: [I, T[I]]) => [ResultIndex, ResultValue]
): Record<ResultIndex, ResultValue> => Object.fromEntries((Object.entries(obj) as [I, T[I]][]).map(transformer)) as Record<ResultIndex, ResultValue>;

export const debounced = <T extends (...args: any[]) => any>(callback: T, delay: number) => {
  let timeout: Maybe<ReturnType<typeof setTimeout>> = undefined;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), delay);
  };
};

export const isTextTruncatedEl = (el: HTMLElement, width: number) => {
  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  ctx!.font = window.getComputedStyle(el).font;
  return Math.floor(Number(ctx!.measureText(el.innerText).width)) > Math.floor(Number(width));
};

export const throttled = <T extends (...args: any[]) => any>(cb: T, delay: number) => {
  let timeout: Nullish<ReturnType<typeof setTimeout>> = null;

  return (...args: Parameters<T>) => {
    if (timeout === null) {
      cb(...args);
      timeout = setTimeout(() => {
        timeout = null;
      }, delay);
    }
  };
};
