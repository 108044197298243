<template>
  <transition
    appear
    class="tw-block md:tw-hidden tw-fixed tw-w-full tw-overflow-y-auto tw-z-20 tw-card tw-shadow-lg tw-bg-gray-50 tw-pb-1"
    style="max-height: 400px; width: calc(100vw - 10px); left: 4px; bottom: 4px"
    enter-active-class="animate__animated animate__slideInUp animate__faster"
    leave-active-class="animate__animated animate__slideOutDown animate__faster"
  >
    <div
      v-click-outside="
        () => {
          show = false;
        }
      "
      v-if="show && $slots['sidebar']"
    >
      <div class="tw-flex tw-justify-end tw-bg-gray-50">
        <button
          @click="
            () => {
              show = false;
            }
          "
        >
          <i class="fas fa-times tw-text-gray-500" style="font-size: 24px" />
        </button>
      </div>
      <slot name="sidebar"></slot>
    </div>
  </transition>
  <div class="tw-h-full tw-grid baseGrid">
    <div class="tw-hidden md:tw-block tw-h-full tw-overflow-y-auto tw-bg-gray-50">
      <slot name="sidebar"></slot>
    </div>

    <div
      class="tw-bg-gray-50"
      :class="[
        $route.fullPath === '/private/launcher' ? '' : $route.fullPath === '/private/notifications-center' ? 'tw-overflow-y-auto' : 'tw-overflow-y-auto tw-p-4',
      ]"
    >
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useSidebar } from "@/composables/useSidebar";

export default defineComponent({
  setup() {
    const { show } = useSidebar();
    return {
      show,
    };
  },
});
</script>
<style>
.baseGrid {
  grid-template-columns: 1fr;
}

@media (min-width: 820px) {
  .baseGrid {
    grid-template-columns: minmax(0, 300px) minmax(0, 1fr);
  }
}
</style>
