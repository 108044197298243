<template>
  <public-layout justify="center" align="center" cover>
    <div class="tw-flex tw-flex-col tw-h-full tw-justify-center tw-items-center">
      <slot />
    </div>
  </public-layout>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "page-404",
  methods: {
    back2Home() {
      this.$router.replace({
        name: "Home",
      });
    },
  },
});
</script>
