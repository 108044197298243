<template>
  <v-dialog :activator="activator" width="auto" ref="dialog" v-model="isActive" @click:outside="onClickBackOnce">
    <template v-slot="{ isActive }">
      <v-card class="px-8 pt-8 pb-6 d-flex flex-column justify-center">
        <div class="d-flex justify-space-between mb-5">
          <v-card-title class="pa-0">
            <slot name="title"> </slot>
          </v-card-title>
          <v-icon icon="mdi-window-close" color="grey-darken-2" size="28" class="mt-1" @click="onClickBackOnce"></v-icon>
        </div>
        <div @keyup.esc.stop="onClickBackOnce" tabindex="0">
          <slot name="content" :isActive="isActive" />
        </div>
        <v-card-actions :class="['d-flex', removeErrorButton ? 'justify-end' : 'justify-space-between', 'pa-0', 'mt-7']" style="min-height: 36px">
          <v-btn v-if="!removeErrorButton" @click="onClickBackOnce" color="grey-darken-1">
            <strong>ANNULLA</strong>
          </v-btn>
          <v-btn :disabled="disableSuccessButton" @click="onClickConfirm" color="success">
            <strong>CONFERMA</strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script lang="ts">
import { once } from "@/lib/functions";
import { defineComponent, PropType, WatchStopHandle } from "vue";

export default defineComponent({
  name: "base-dialog",

  props: {
    removeErrorButton: {
      default: false,
      type: Boolean,
    },
    activator: {
      type: String as PropType<"parent" | undefined>,
      default: undefined,
    },
  },

  data: () => {
    return {
      isActive: false,
      disableSuccessButton: false,
    };
  },

  setup() {
    return {
      dispatchEvent,
      CustomEvent,
    };
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClickBackOnce: () => {},

    onClickConfirm() {
      dispatchEvent(new CustomEvent("_click:confirm", { bubbles: false }));
      this.$emit("click:confirm");
      this.isActive = false;
    },

    onClickBack() {
      dispatchEvent(new CustomEvent("_click:back", { bubbles: false }));
      this.$emit("click:back");
      this.isActive = false;
    },

    open(ctx: { disableSuccessButton?: boolean }) {
      return new Promise((res, rej) => {
        const unwatchers: WatchStopHandle[] = [];
        Object.keys(ctx).forEach((k) => {
          // @ts-expect-error todo
          if (this.$data[k] !== undefined) {
            // @ts-expect-error todo
            this.$data[k] = ctx[k];
            unwatchers.push(
              this.$watch(
                // @ts-expect-error todo
                () => ctx[k],
                (v: any) => {
                  // @ts-expect-error todo
                  this.$data[k] = v;
                }
              )
            );
          }
        });

        this.isActive = true;

        addEventListener(
          "_click:confirm",
          (e) => {
            e.stopPropagation();
            unwatchers.forEach((u) => u());
            res(undefined);
          },
          { once: true }
        );

        addEventListener(
          "_click:back",
          (e) => {
            e.stopPropagation();
            unwatchers.forEach((u) => u());
            rej(undefined);
          },
          { once: true }
        );
      });
    },

    onEsc(e: Event) {
      if ((e as KeyboardEvent).key === "Escape") {
        this.onClickBackOnce();
      }
    },
  },

  watch: {
    isActive(v) {
      if (v) {
        addEventListener("keyup", this.onEsc, { once: true });
        this.onClickBackOnce = once(this.onClickBack);
      }
    },
  },

  expose: ["open"],

  emits: ["click:back", "click:confirm"],
});
</script>
