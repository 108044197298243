<template>
  <table>
    <tr>
      <th v-for="(k, label) of labels" :key="k">
        <slot :name="`${value}-header`" :label="label">
          {{ label }}
        </slot>
      </th>
    </tr>
    <tr v-for="(item, i) of items" :key="i">
      <td v-for="(value, j) of values" :key="j">
        <slot :name="`${value}-col`" :item="item" :value="value">
          {{ item[value] }}
        </slot>
      </td>
    </tr>
  </table>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    headers: {
      type: Array as PropType<
        Array<{
          label: string;
          value: string;
        }>
      >,
      required: true,
    },
    items: {
      type: Array as PropType<Array<Record<string, any>>>,
      required: true,
    },
  },
  computed: {
    labels() {
      return this.headers.map((h) => h.label);
    },
    values() {
      return this.headers.map((h) => h.value);
    },
  },
});
</script>
