<template>
  <!-- WAITING FOR FCM SUPPORT STATUS -->
  <div v-if="showUnsupported === null"></div>
  <!-- FCM IS NOT SUPPORTED  -->
  <div v-else-if="showUnsupported">
    <div class="tw-flex tw-mb-4 tw-border-l-4 tw-border-red-500 tw-p-3 tw-bg-red-200 tw-rounded-md">
      <div class="tw-flex tw-items-center">
        <i class="fas fa-bell-slash tw-text-2xl tw-text-red-500"></i>
      </div>
      <p class="tw-ml-3 tw-text-red-500">
        Questo browser non supporta le notifiche, utilizza uno tra
        <a href="https://www.google.com/intl/it_it/chrome/" target="_blank" class="underline">Chrome</a>,
        <a href="https://www.mozilla.org/it/firefox/new/" target="_blank" class="underline">Firefox</a> o
        <a href="https://www.microsoft.com/it-it/edge" target="_blank" class="underline">Edge</a>
      </p>
    </div>
  </div>
  <!-- FCM IS SUPPORTED -->
  <div v-else>
    <input
      type="checkbox"
      name="notification-permissions"
      @click.prevent="askForNotifications"
      :disabled="notificationStatus !== 'default' || disabled"
      :checked="notificationStatus === 'granted'"
      :indeterminate="notificationStatus === 'denied'"
      style="transform: scale(1.2)"
    />
    <label for="notification-permissions" class="tw-ml-3">
      {{
        notificationStatus === "granted"
          ? "Notifiche abilitate"
          : notificationStatus === "denied"
          ? "Notifiche disabilitate"
          : "Abilita le notifiche per un servizio migliore"
      }}
    </label>
    <div v-if="notificationStatus === 'denied'" class="tw-flex tw-mt-4 tw-border-l-4 tw-border-red-500 tw-p-3 tw-bg-red-200 tw-rounded-md">
      <div class="tw-flex tw-items-center">
        <i class="fas fa-bell-slash tw-text-2xl tw-text-red-500"></i>
      </div>
      <p class="tw-ml-3 tw-text-red-500">
        Permesso alle notifiche negato! Per scoprire come attivarle, apri il <a href="#" class="underline">centro assistenza</a>
      </p>
    </div>
    <shared-modal v-model:isOpen="isOpen">
      <template #content>
        <div class="tw-flex tw-flex-col tw-items-center">
          <i class="fas fa-exclamation-triangle tw-text-warning tw-text-5xl" />
          <p class="tw-text-warning tw-font-bold tw-text-2xl tw-text-center">Attenzione</p>
        </div>
        <p class="tw-mx-3 tw-mt-2 tw-text-center">
          Effettuare il login senza attivare le notifiche non ti permetterà di usufruire di tutte le potenzialità di Amministro.io
        </p>
        <div class="tw-flex tw-flex-wrap tw-justify-between tw-pt-4 tw-w-full">
          <button shape="outlined" class="tw-w-full tw-m-2 tw-order-last" @click.stop="proceed">Accedi comunque</button>
          <button shape="default" class="tw-w-full tw-m-2 tw-bg-primary-light" @click.stop="askForNotificationsAndProceed">Abilita</button>
        </div>
      </template>
    </shared-modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useFCM } from "@/composables/useFCM";
import { useUserStore } from "@/stores/user";

export default defineComponent({
  name: "notifications-ask-permissions",

  emits: ["proceed"],

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const { askForNotifications, notificationStatus, isFCMSupported } = useFCM();
    const userStore = useUserStore();
    return {
      isFCMSupported,
      askForNotifications,
      notificationStatus,
      userStore,
    };
  },

  async created() {
    this.showUnsupported = !(await this.isFCMSupported());
  },

  data: () => ({
    alreadyAsked: false,
    isOpen: false,
    showUnsupported: undefined as undefined | boolean,
  }),

  methods: {
    askPermissionWithModal() {
      this.isOpen = true;
    },

    proceed() {
      this.isOpen = false;
      this.$emit("proceed");
    },

    async askForNotificationsAndProceed() {
      if (!this.alreadyAsked) {
        // L'utente potrebbe chiudere il modal senza modificare le preferenze di notifica, premendo 'ACCEDI COMUNUQUE'
        // In questo caso, non gli deve essere chiesto una seconda volta di specificare di abilitare o meno le notifiche
        this.alreadyAsked = true;
        await this.askForNotifications();
        this.proceed();
      }
    },
  },

  watch: {
    "userStore.user"(v) {
      if (v) {
        this.isOpen = this.notificationStatus === "default" && !this.alreadyAsked;
      }
    },
  },
});
</script>
