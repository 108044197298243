import { makeBinded } from "@/proxies";

class DialogService {
  public showDialog(
    dialogInfo: { title: string; description: string; successButtonText: string; errorButtonText: string },
    showErrorButton = true,
    persistent = true
  ) {
    return new Promise((res, rej) => {
      window.dispatchEvent(new CustomEvent("show-dialog", { detail: { ...dialogInfo, showErrorButton, persistent } }));

      window.addEventListener(
        "success-button-click",
        () => {
          window.removeEventListener("error-button-click", rej);
          res(null);
        },
        { once: true }
      );
      window.addEventListener(
        "error-button-click",
        () => {
          window.removeEventListener("success-button-click", res);
          rej(null);
        },
        { once: true }
      );
    });
  }
  public closeDialog() {
    window.dispatchEvent(new CustomEvent("close-dialog"));
  }
}

export const dialogService = makeBinded(DialogService);
