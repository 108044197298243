import { RouteRecordNormalized } from "vue-router";

export const getRequiredParams = async ({ routeProperty, value }: { routeProperty: keyof Pick<RouteRecordNormalized, "path" | "name">; value: string }) => {
  const routes = (await import("@/router/index")).default.getRoutes();
  const matched = routes.find((r) => r[routeProperty] === value);
  if (matched) {
    return [...matched.path.matchAll(/\/:[^:?/]*/g)].map((match) => match[0].substring(2));
  } else {
    console.error(`Could not find a match for route with property '${routeProperty}' and value '${value}'`);
    return [];
  }
};
