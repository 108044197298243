<template>
  <div class="tw-fixed tw-bottom-0 tw-right-0 tw-z-10">
    <transition-group enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__slideOutRight">
      <div
        v-for="not in notifications"
        :key="not.messageId"
        @click="openNotification(not.data)"
        class="tw-rounded tw-p-3 tw-mb-4 tw-mr-4 tw-shadow-md tw-flex tw-bg-gray-700 tw-text-white tw-border-l-4 tw-border-blue-500 tw-relative"
      >
        <div class="tw-mr-2">
          <p>{{ not.data.notificationTitle }}</p>
        </div>
        <div class="tw-flex tw-items-start">
          <button @click.stop="dimissNotification(not)">
            <i class="fas fa-times-circle" />
          </button>
        </div>
        <div class="tw-bg-white tw-absolute tw-bottom-0 tw-left-0 timed" />
      </div>
    </transition-group>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useFCM } from "@/composables/useFCM";
import { useUserStore } from "@/stores/user";
import { NotificationFCMDTO } from "@/dto/notifications/NotificationFCM.dto";

export default defineComponent({
  name: "notifications-list",
  setup() {
    const userStore = useUserStore();
    const {
      notifications,
      startListeningForNotifications,
      stopListeningForNotifications,
      notificationStatus,
      isFCMSupported,
      addOnNewNotificationCallback,
      removeOnNewNotificationCallback,
      openNotification,
    } = useFCM();

    return {
      isFCMSupported,
      notificationStatus,
      startListeningForNotifications,
      stopListeningForNotifications,
      notifications,
      userStore,
      addOnNewNotificationCallback,
      removeOnNewNotificationCallback,
      openNotification,
    };
  },

  data: () => ({
    NOTIFICATION_SECONDS: 6,
  }),

  created() {
    this.addOnNewNotificationCallback({
      name: "showNoty",
      cb: this.onNewNotification,
    });
  },

  beforeUnmount() {
    this.removeOnNewNotificationCallback("showNoty");
  },

  methods: {
    dimissNotification(not: NotificationFCMDTO) {
      this.notifications = this.notifications.filter((noty) => noty.messageId !== not.messageId);
    },

    onNewNotification() {
      this.$nextTick().then(() => {
        setTimeout(() => this.notifications.shift(), this.NOTIFICATION_SECONDS * 1000 - 100);
      });
    },
  },

  watch: {
    async "userStore.user"(v) {
      if (!(await this.isFCMSupported())) return;
      if (v) {
        if (this.notificationStatus === "granted") this.startListeningForNotifications();
        if (this.notificationStatus === "denied") this.stopListeningForNotifications();
      }
    },
  },
});
</script>
<style scoped>
/* TODO: rifattorizzare usando Dynamic State CSS */
.timed {
  height: 1px;
  animation: elapsed 6s linear;
}

@keyframes elapsed {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
</style>
