<template>
  <div>
    <div @click.stop="open" class="tw-inline-block">
      <slot name="open" />
    </div>
    <Teleport to="body">
      <div class="overlay tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-start tw-p-6" v-if="opened" @click.self="close" id="shared-modal">
        <Transition class="animate__animated animate__zoomIn animate__faster">
          <div v-if="showModal" class="tw-modal tw-card tw-w-full tw-bg-white tw-h-auto" style="max-width: 500px">
            <slot name="close" :key="key" />
            <slot name="content" :close="close" :lock="lock" :unlock="unlock" :key="key" />
          </div>
        </Transition>
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { defineComponent } from "vue";
import { useKeyUp } from "@/composables/useKeyUp";

export default defineComponent({
  setup() {
    const opened = ref(false);
    const showModal = ref(false);
    const locked = ref(false);
    const key = ref(Math.random() + "");
    const { detect } = useKeyUp();

    return {
      detect,
      locked,
      opened,
      showModal,
      key,
    };
  },

  props: {
    isOpen: Boolean,
    persistent: Boolean,
  },

  created() {
    if (this.persistent) this.lock();

    this.detect({
      Esc: this.close,
      Escape: this.close,
    });
  },

  methods: {
    lock() {
      this.locked = true;
    },
    unlock() {
      this.locked = false;
    },
    close(force?: boolean) {
      if (force && typeof force === "boolean") this.locked = false;
      this.opened = false || this.locked;
    },
    open() {
      this.key = Math.random() + "";
      this.opened = true;
    },
  },

  watch: {
    isOpen(v) {
      this.opened = v;
    },

    async opened(v) {
      this.showModal = v;
      this.$emit("update:isOpen", v);
      if (v) await this.$nextTick();
    },
  },
});
</script>
