<template>
  <div v-bind="$attrs" class="tw-flex tw-rounded-md tw-p-2 tw-mb-4" :class="classFromType">
    <div class="tw-flex tw-flex-column tw-items-center tw-pl-2 tw-px-4">
      <slot name="icon"> <i :class="iconFromType" style="font-size: 36px" /> </slot>
    </div>
    <div>
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  type: {
    required: false,
    type: String as PropType<"info" | "warning" | "success" | "error">,
    default: "info",
  },
});

const classFromType = computed(() => {
  switch (props.type) {
    case "info":
      return "tw-bg-blue-600 tw-text-white";
    case "success":
      return "bg-success tw-text-white";
    case "warning":
      return "bg-warning tw-text-white";
    case "error":
      return "bg-red tw-text-white";
    default:
      return "";
  }
});

const iconFromType = computed(() => {
  switch (props.type) {
    case "info":
      return "fas fa-info-circle";
    case "error":
      return "fas fa-info-circle";
    case "warning":
      return "fas fa-exclamation-triangle";
    default:
      return "";
  }
});
</script>

<style scoped></style>
