import * as Sentry from "@sentry/vue";

import { version } from "../../package.json";

export class SentryConfig {
  static setStrategy(env: "development" | "staging" | "production") {
    switch (env) {
      case "production":
        return { get: this._productionConfig };
      case "staging":
        return { get: this._stagingConfig };
      case "development":
        return { get: () => ({}) };
    }
  }

  private static _baseConfig = () => ({
    dsn: "https://990d35cef72cfb8fc9e32282ef644d3f@o4506353507762176.ingest.us.sentry.io/4506932771291136",
    release: version,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  });

  private static _productionConfig = () => ({
    ...this._baseConfig(),
    environment: "production",
    tracePropagationTargets: [/^https:\/\/core.api.amministro.io/],
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.01,
  });

  private static _stagingConfig = () => ({
    ...this._baseConfig(),
    environment: "staging",
    tracePropagationTargets: [/^https:\/\/amministro-io-dev-3ti73svu\.ew\.gateway\.dev\//],
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}
