import { uuid } from "@/lib/random";
import { Plugin } from "vue";

export type AugmentedWindow = typeof window & { ctx: string };
const augWindow = window as AugmentedWindow;

const ctxPlugin: Plugin = {
  install: (app) => {
    augWindow.ctx = uuid();
    app.config.globalProperties.$ctx = augWindow.ctx;
  },
};

export { ctxPlugin, augWindow };
