import { RouteLocationNormalized as Route } from "vue-router";
import { useUserStore } from "@/stores/user";
import { Profile } from "@amministro-io-packages/auth-interfaces";

export function checkServicesPagePermissions(to: Route, from: Route) {
  const { profiles } = useUserStore();

  const redirect = () => {
    if (from.name === "Check") return "/";
    else return false;
  };

  const profile = (profiles as Profile[]).find((pr) => pr.profile_id === to.params.idElement);

  if (profile?.company_type === "sys_organizations") {
    const { amministroio, anagrafiche, operativita } = profile.moduli;
    if (amministroio && anagrafiche && operativita) return true;
    else return redirect();
  } else return redirect();
}
