/**
 *
 */
export function sleep(millis: number) {
  return new Promise((r) => setTimeout(r, millis));
}

/**
 *
 * @param array
 * @returns
 */
export const makeIterator = <T extends Array<unknown>>(array: T) => {
  let nextIdx = 0;
  return {
    next() {
      return nextIdx < array.length ? { value: array[nextIdx++], done: false } : { done: true };
    },
  };
};

/**
 * TODO: documentazione
 * @param e1
 * @param e2
 * @returns
 */
const cmpPrimitive = <T>(e1: T, e2: T): number => {
  if (typeof e1 === "string") {
    return e1.localeCompare(e2 as typeof e1);
  } else {
    return e1 > e2 ? 1 : e1 === e2 ? 0 : -1;
  }
};

/**
 * TODO: documentazione
 * @param order
 * @returns
 */
export const compare = <O extends Record<string, unknown> | number | string>(order: "asc" | "desc") => {
  return (strategy?: keyof O): ((a: O, b: O) => number) => {
    const sort = order === "asc" ? 1 : -1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return (a: O, b: O) => (typeof a === "object" ? cmpPrimitive(a[strategy!], b[strategy!]) : cmpPrimitive(a, b)) * sort;
  };
};

export const valueFromType = (type: "string" | "object" | "number") => {
  switch (type) {
    case "string":
      return "";
    case "object":
      return {};
    case "number":
      return 0;
    default:
      return null;
  }
};

export const debounced = <T extends (...args: any[]) => any>(callback: T, delay: number) => {
  let timeout: NodeJS.Timeout | undefined = undefined;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), delay) as unknown as NodeJS.Timeout;
  };
};
