<template>
  <button>
    <i
      class="fas fa-arrow-left tw-text-gray-500"
      style="font-size: 24px"
      @click="
        $router.push({
          name: 'Launcher',
        })
      "
    />
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
