<template>
  <div class="hoverBound" onmouseover="this.children[1].setAttribute('hover', 'true')" onmouseout="this.children[1].setAttribute('hover', 'false')">
    <slot name="activator"></slot>
    <div class="sharedTooltip">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      x: 0,
      childBoundingRect: {} as DOMRect,
    };
  },
});
</script>

<style scoped>
.hoverBound {
  position: relative;
  transform: scale(1, 1);
  width: max-content;
  height: max-content;
}
.sharedTooltip {
  position: absolute;
  left: 50%;
  top: 0;

  transform-origin: top left;
  transform: scale(0, 0) translateX(-50%) translateY(-100%);

  max-width: 350px;
  width: max-content;

  border-radius: 8px;
  padding: 3px 15px;

  background-color: rgba(0, 0, 0, 0.6);
  color: white;

  display: flex;
  justify-content: center;

  opacity: 0;
  transition: 0.2s ease;
}

.text {
  width: 100%;
  word-wrap: break-word;
}

.sharedTooltip[hover="true"] {
  opacity: 1;
  transform: scale(1, 1) translateX(-50%) translateY(-100%);
  transition: transform 0.2s;
}
</style>
