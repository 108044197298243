export default {
  path: "usr-company/:idElement",
  name: "MyUsrCompany",
  component: () => import(/* webpackChunkName: "my-usr-company-view" */ "@/views/private/MyUsrCompany.vue"),
  props: true,
  children: [
    {
      path: "communications-preference",
      name: "CommunicationsPreference",
      component: () => import(/* webpackChunkName: "my-usr-company-anagrafica" */ "@/components/my-usr-company/CommunicationsPreference.vue"),
    },
  ],
};
